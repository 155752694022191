import DataViewer from "../../Components/dataViewer/DataViewer.component";
import "./StockList.styles.scss";

const TRIAL_DATA = [];
const formFields = ["id", "items", "quantity", "date"];

const StockList = () => {
  return (
    <div className="stock_container">
      <div className="stock_breadcrumbs">Dashboard {">"} Stock Material</div>
      <div className="stock_list">
        <DataViewer props={[TRIAL_DATA, formFields]} />
      </div>
      <div className="stock_pagination">Pagination</div>
    </div>
  );
};

export default StockList;
