import { createContext, useState } from "react";

export const AddNewFormTogglerContext = createContext({
  isAddNewFormOpen: false,
  setIsAddNewFormOpen: () => {},
});

export const AddNewFormTogglerContextProvider = ({ children }) => {
  const [isAddNewFormOpen, setIsAddNewFormOpen] = useState(null);
  const value = { isAddNewFormOpen, setIsAddNewFormOpen };
  return (
    <AddNewFormTogglerContext.Provider value={value}>
      {children}
    </AddNewFormTogglerContext.Provider>
  );
};
