import "./RawMatItemInfo.styles.scss";
import AddNewButton from "../../Components/addNewButton/addNewButton.component";
import AddNewData from "../../Components/addNewData/addNewData.component";
const TRIAL_DATA = [
  {
    date: "21-12-2022",
    quantity: "250 kg",
    time: "10:53 AM",
    action: "Actions",
  },
  {
    date: "21-12-2022",
    quantity: "250 kg",
    time: "10:53 AM",
    action: "Actions",
  },
  {
    date: "21-12-2022",
    quantity: "250 kg",
    time: "10:53 AM",
    action: "Actions",
  },
  {
    date: "21-12-2022",
    quantity: "250 kg",
    time: "10:53 AM",
    action: "Actions",
  },
  {
    date: "21-12-2022",
    quantity: "250 kg",
    time: "10:53 AM",
    action: "Actions",
  },
  {
    date: "21-12-2022",
    quantity: "250 kg",
    time: "10:53 AM",
    action: "Actions",
  },
  {
    date: "21-12-2022",
    quantity: "250 kg",
    action: "Actions",
    time: "10:53 AM",
  },
];

const RawMatItemInfo = () => {
  return (
    <div className="raw-mat-info">
      <AddNewData />
      <div className="raw-mat-info_header">
        <div className="raw-mat-info_header-head">Item : 21*10</div>
        <AddNewButton />
      </div>
      <div className="raw-mat-info_data_container">
        {TRIAL_DATA.map((dt) => {
          const { date, time, quantity, action } = dt;
          return (
            <div className="raw-mat-info_data">
              {/*<div className="raw-mat-info_data_header">
                <p className="raw-mat-info_data_header-header">DATE</p>
                <p className="raw-mat-info_data_header-header">QUANTITY</p>
                <p className="raw-mat-info_data_header-header">ACTIONS</p>
          </div>*/}
              <div className="raw-mat-info_data_items">
                <p className="raw-mat-info_data_items-item">{date}</p>
                <p className="raw-mat-info_data_items-item">{time}</p>
                <p className="raw-mat-info_data_items-item">{quantity}</p>
                <p className="raw-mat-info_data_items-item">{action}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RawMatItemInfo;
