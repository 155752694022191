import { AddNewFormTogglerContext } from "../../Contexts/AddNewFormTogglerContext/AddNewFormTogglerContext.context";
import { useContext } from "react";
import "./addNewButton.styles.scss";

const AddNewButton = () => {
  const { setIsAddNewFormOpen } = useContext(AddNewFormTogglerContext);
  return (
    <button
      className="button-add-item"
      onClick={() => {
        setIsAddNewFormOpen(true);
      }}
    >
      + Add Raw Material
    </button>
  );
};

export default AddNewButton;
