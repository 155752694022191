import "./Dashboard.styles.scss";

import { useContext } from "react";
import { UserContext } from "../../Contexts/UserContext/UserContext.context";
import { Link, useNavigate, Navigate, Outlet } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const { setIsUserLogged } = useContext(UserContext);
  const isUser = sessionStorage.getItem("IsUserLogged");
  console.log(isUser);
  if (isUser) {
    if (isUser === "true") {
      return (
        /*<>
          <h1> This is Dashboard</h1>
          <button
            onClick={() => {
              setIsUserLogged(false);
              sessionStorage.clear();
            }}
          >
            Log Out
          </button>
          <Link to={"/trial"}>Trial</Link>
          </>*/
        <div className="dashboard">
          <div className="dashboard_topbar">
            <header className="top-bar">
              <Link className="logo" to={"/Dashboard"}>
                Viral Bright
              </Link>
              <div
                className="power-icon"
                onClick={() => {
                  setIsUserLogged(false);
                  sessionStorage.clear();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-power"
                >
                  <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
                  <line x1="12" y1="2" x2="12" y2="12"></line>
                </svg>
              </div>
            </header>
          </div>
          <div className="dashboard_bottom">
            <div className="dashboard_bottom_navbar">
              <nav className="sidebar">
                <ul className="side-nav">
                  <div
                    className="side-nav_items"
                    onClick={() => {
                      navigate("/Dashboard/rawmat");
                    }}
                  >
                    <Link className="side-nav_link" to={"/Dashboard/rawmat"}>
                      Raw Material
                    </Link>
                  </div>
                  <div
                    className="side-nav_items"
                    onClick={() => {
                      navigate("/Dashboard/finishedgoods");
                    }}
                  >
                    <Link
                      className="side-nav_link"
                      to={"/Dashboard/finishedgoods"}
                    >
                      Finish Goods
                    </Link>
                  </div>
                  <div
                    className="side-nav_items"
                    onClick={() => {
                      navigate("/Dashboard/stocklist");
                    }}
                  >
                    <Link className="side-nav_link" to={"/Dashboard/stocklist"}>
                      Stock List
                    </Link>
                  </div>
                </ul>
              </nav>
            </div>
            <div className="dashboard_bottom_content">
              <Outlet />
            </div>
          </div>
        </div>
      );
    } else {
      return <Navigate to={"/"} />;
    }
  }
  return <Navigate to={"/"} />;
};

export default Dashboard;
