import DataViewer from "../../Components/dataViewer/DataViewer.component";
import "./RawMat.styles.scss";

const TRIAL_DATA = [
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
  },
];

const formFields = ["id", "items", "quantity", "date", "anyways", "whaterver"];
const RawMat = () => {
  console.log(formFields);
  return (
    <div className="raw_container">
      <div className="raw_breadcrumbs">Dashboard {">"} Raw Material</div>
      <div className="raw_list">
        <DataViewer props={[TRIAL_DATA, formFields]} />
      </div>
      <div className="raw_pagination">Pagination</div>
    </div>
  );
};

export default RawMat;
