import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./Routes/Login/Login.component";
import Dashboard from "./Routes/Dashboard/Dashboard.component";
import RawMat from "./Routes/RawMat/RawMat.component";
import FinishedGoods from "./Routes/FinishedGoods/FinishedGoods.components";
import StockList from "./Routes/StockList/StockList.component";
import RawMatItemInfo from "./Routes/RawMatItemInfo/RawMatItemInfo.component";

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />}>
          <Route path="rawmat" element={<RawMat />} />
          <Route path="rawmatiteminfo" element={<RawMatItemInfo />} />

          <Route path="finishedgoods" element={<FinishedGoods />} />
          <Route path="stocklist" element={<StockList />} />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
