import "./DataViewer.styles.scss";
import AddNewData from "../addNewData/addNewData.component";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AddNewButton from "../addNewButton/addNewButton.component";

const DataViewer = ({ props }) => {
  const [dataPacket, formFields] = props;
  // Form Fields
  console.log(formFields);
  const navigate = useNavigate();
  useEffect(() => {
    const newDataViewerHeight = `${dataPacket.length * 4.8 + 12}rem`;
    document.documentElement.style.setProperty(
      "--dataviewer-height",
      newDataViewerHeight
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (dataPacket[0]) {
    const objKeys = Object.keys(dataPacket[0]);

    return (
      <div className="data-viewer_container">
        <AddNewData fields={formFields} />
        <div className="data-viewer_header">
          <div className="data-viewer_header-title">Raw Material List</div>

          <AddNewButton />
        </div>
        <div className="data-viewer_list">
          <div className="data-viewer_list-items">
            {objKeys.map((key) => {
              return (
                <p className="data-viewer_list-item_header">
                  {key.toUpperCase()}
                </p>
              );
            })}
            <p className="data-viewer_list-item_header">ACTIONS</p>
            {/*<p className="data-viewer_list-item_header">Quantity</p>
            <p className="data-viewer_list-item_header">Date</p>
          <p className="data-viewer_list-item_header">Actions</p> */}
          </div>
          {dataPacket[0] ? (
            dataPacket.map((data) => {
              const dataVal = Object.values(data);
              return (
                <div className="data-viewer_list-items">
                  {dataVal.map((data) => {
                    return <p className="data-viewer_list-item">{data}</p>;
                  })}
                  <p className="data-viewer_list-action">
                    <button
                      className="data-viewer_list-action_button"
                      onClick={() => {
                        navigate("/dashboard/rawmatiteminfo");
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#337DBD"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-book"
                      >
                        <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path>
                        <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path>
                      </svg>
                    </button>

                    <button className="data-viewer_list-action_button">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#51C13F"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-arrow-right-circle"
                      >
                        <circle cx="12" cy="12" r="10"></circle>
                        <polyline points="12 16 16 12 12 8"></polyline>
                        <line x1="8" y1="12" x2="16" y2="12"></line>
                      </svg>
                    </button>
                  </p>
                </div>
              );
            })
          ) : (
            <div className="data-viewer_no-data">No data</div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="data-viewer_container">
        <AddNewData fields={formFields} />
        <div className="data-viewer_header">
          <div className="data-viewer_header-title">Raw Material List</div>
          <AddNewButton />
        </div>
        <div className="data-viewer_no-data">No data</div>
      </div>
    );
  }
};

export default DataViewer;
