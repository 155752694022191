import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBaJ0su4HlMeRHtXGNPkaUj4COnCNSrSZM",
  authDomain: "viralbrighterp.firebaseapp.com",
  projectId: "viralbrighterp",
  storageBucket: "viralbrighterp.appspot.com",
  messagingSenderId: "1021342008481",
  appId: "1:1021342008481:web:0395bb36a8bcb5fc699ce0",
  measurementId: "G-3SVLM7NFG9",
};

// Initialize Firebase App

const firebaseApp = initializeApp(firebaseConfig);

// Getting Authentication ID
export const auth = getAuth(firebaseApp);

export const signUserUp = () => signInAnonymously(auth);

// Initializing Firestore
const db = getFirestore(firebaseApp);

let userDocRef;

// Checking If User Exists
export const checkUser = async (UserId) => {
  userDocRef = await doc(db, "users", UserId);

  const userSnapshot = await getDoc(userDocRef);

  if (userSnapshot.exists()) {
    return true;
  }

  return false;
};

// Creating User
export const createUser = async (data) => {
  const { userName, userEmail } = data;
  if (userDocRef) {
    try {
      await setDoc(userDocRef, {
        userName,
        userEmail,
      });
    } catch (err) {
      return err;
    }
  }
  alert("User created successfully");
  return true;
};

//Authenticating User

export const ValidateUser = async (id, upassword) => {
  const docRef = await doc(db, "userCreds", id);
  const userSnapshot = await getDoc(docRef);

  console.log(userSnapshot);

  if (userSnapshot.exists()) {
    const { userId, password } = userSnapshot.data();

    if (id === userId && upassword === password) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};
