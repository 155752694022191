import LoginForm from "../../Components/loginForm/LoginForm.component";
import "./Login.styles.scss";
import { checkUser, signUserUp } from "../../Utils/firebase/firbase.utils";
import { useEffect, useState } from "react";
import SignInPopup from "../Signin/Signin.component";

const Login = () => {
  const [userExists, setUserExists] = useState(true);
  // Signing User anonymously

  useEffect(() => {
    const signIn = async () => {
      const { user } = await signUserUp();
      const tempUserExists = await checkUser(user.uid);

      setUserExists(tempUserExists);
      //setUserExists(false);
    };

    signIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="login-background">
      <SignInPopup trigger={!userExists} setTrigger={setUserExists} />
      <LoginForm />
    </div>
  );
};
export default Login;
