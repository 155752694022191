import DataViewer from "../../Components/dataViewer/DataViewer.component";
import "./FinishedGoods.styles.scss";

const TRIAL_DATA = [
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
    action: "actions",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
    action: "actions",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
    action: "actions",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
    action: "actions",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
    action: "actions",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
    action: "actions",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
    action: "actions",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
    action: "actions",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
    action: "actions",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
    action: "actions",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
    action: "actions",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
    action: "actions",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
    action: "actions",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
    action: "actions",
  },
  {
    items: "21*10",
    quantity: "100tn",
    date: "21-10-2022",
    action: "actions",
  },
];
const formFields = ["id", "items", "quantity", "date"];
const FinishedGoods = () => {
  return (
    <div className="finished_container">
      <div className="finished_breadcrumbs">Dashboard {">"} Finished Goods</div>
      <div className="finished_list">
        <DataViewer props={[TRIAL_DATA, formFields]} />
      </div>
      <div className="finished_pagination">Pagination</div>
    </div>
  );
};

export default FinishedGoods;
