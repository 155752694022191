import { useState } from "react";
import { createUser } from "../../Utils/firebase/firbase.utils";
import "./Signin.style.scss";

const defaultUserData = {
  userName: "",
  userEmail: "",
};

const SignInPopup = (props) => {
  //User Details
  const [formFeilds, setFormFeilds] = useState(defaultUserData);
  const { userName, userEmail } = formFeilds;

  // Handle on submit
  const handleOnSigninSubmit = async (event) => {
    event.preventDefault();
    const result = await createUser(formFeilds); // CREATING USERS
    console.log(result);
    props.setTrigger(true);
  };

  //Handle on change

  const handleOnSigninChange = (event) => {
    const { name, value } = event.target;
    setFormFeilds({ ...formFeilds, [name]: value });
    console.log(formFeilds);
  };
  return props.trigger ? (
    <div className="signin-popup">
      <h1 className="signin-warning-text">
        You need to Sign Up before entering!
      </h1>
      <div className="signin-card">
        <h1 className="signin-text">Sign Up</h1>
        <form className="signin-form" onSubmit={handleOnSigninSubmit}>
          <div className="signin-form-content">
            <input
              className="signin-form-content-item"
              placeholder="Username"
              type="text"
              name="userName"
              value={userName}
              onChange={handleOnSigninChange}
              autoComplete="off"
              required
            />

            <input
              className="signin-form-content-item"
              placeholder="Email"
              type="email"
              name="userEmail"
              value={userEmail}
              onChange={handleOnSigninChange}
              autoComplete="off"
              required
            />
          </div>
          <button className="signin-submit-button">
            <p className="button-signin-text">Submit</p>
          </button>
        </form>
      </div>
      ;
    </div>
  ) : (
    ""
  );
};

export default SignInPopup;
