import { useState } from "react";
import { useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { UserContext } from "../../Contexts/UserContext/UserContext.context";
import { ValidateUser } from "../../Utils/firebase/firbase.utils";
import "./LoginForm.styles.scss";

const defaultUserCredentials = {
  userId: "",
  userPassword: "",
};

const LoginForm = () => {
  //Check if user Logged

  const isUser = sessionStorage.getItem("IsUserLogged");

  // Use Navigate

  const navigate = useNavigate();
  //User Context isUserLogged

  const { setIsUserLogged } = useContext(UserContext);

  // REQUIRED FEILDS
  const [formLoginFeilds, setformLoginFeilds] = useState(
    defaultUserCredentials
  );
  const { userId, userPassword } = formLoginFeilds;

  // Handle on submit
  const handleOnSubmit = async (event) => {
    event.preventDefault();

    const response = await ValidateUser(userId, userPassword);

    if (!response) {
      alert("Try Again incorrect credentials");
      return;
    } else {
      setIsUserLogged(true);
      navigate("/dashboard");
    }
  };
  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setformLoginFeilds({ ...formLoginFeilds, [name]: value });
  };
  if (isUser && isUser === "true") {
    return <Navigate to={"/dashboard"} />;
  } else {
    return (
      <div className="login-card">
        <h1 className="login-text">Log In</h1>
        <form className="login-form" onSubmit={handleOnSubmit}>
          <div className="login-form-content">
            <input
              className="login-form-content-item"
              placeholder="Username"
              type="text"
              name="userId"
              value={userId}
              onChange={handleOnChange}
              autoComplete="off"
              required
            />
            <input
              className="login-form-content-item"
              placeholder="Password"
              type="password"
              name="userPassword"
              value={userPassword}
              onChange={handleOnChange}
              autoComplete="off"
              required
            />
            <span className="login-form-content-item-after"></span>
          </div>
          <button className="login-submit-button">
            <p className="button-text">Submit</p>
          </button>
        </form>
      </div>
    );
  }
};
export default LoginForm;
