import { createContext, useReducer } from "react";

export const UserContext = createContext({
  isUserLogged: null,
  setIsUserLogged: () => null,
});

const USER_CONTEXT_ACTION_TYPE = {
  SET_USER: "SET_USER",
};

const UserReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_CONTEXT_ACTION_TYPE.SET_USER:
      return {
        ...state,
        isUserLogged: payload,
      };
    default:
      throw new Error(`Unhandled type ${type} in user Reducer`);
  }
};
const INITIAL_STATE = {
  isUserLogged: null,
};
export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, INITIAL_STATE);

  const setIsUserLogged = (bool) => {
    console.log(bool);
    dispatch({ type: USER_CONTEXT_ACTION_TYPE.SET_USER, payload: bool });

    sessionStorage.setItem("IsUserLogged", bool);
  };
  const value = { state, setIsUserLogged };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
