import { useContext } from "react";

import { AddNewFormTogglerContext } from "../../Contexts/AddNewFormTogglerContext/AddNewFormTogglerContext.context";
import { ToNormalCase } from "../../Utils/ToNormalCase/ToNormalCase.utils";
import "./addNewData.styles.scss";

const AddNewData = ({ fields }) => {
  // Pop up Toggler Context
  console.log(fields);
  const { isAddNewFormOpen, setIsAddNewFormOpen } = useContext(
    AddNewFormTogglerContext
  );

  if (isAddNewFormOpen) {
    return (
      <div className="add-data-form">
        <div className="add-data-form_container">
          <div className="add-data-form_header">
            <button
              className="add-data-form_header_close"
              onClick={() => {
                setIsAddNewFormOpen(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
            <h1 className="add-data-form_header_form">Add Data here</h1>
          </div>
          <div className="add-data-form_form-container">
            <form className="add-data-form_form-form">
              <div className="add-data-form_form-form_container">
                {fields.map((field) => {
                  let type = "text";
                  if (field === "id") {
                    return <></>;
                  }
                  if (field === "date") {
                    type = "date";
                  }
                  return (
                    <div className="add-data-form_form-form_container-item">
                      <label className="add-data-form_form-form_container-item-label">
                        {ToNormalCase(field)}
                      </label>
                      <input
                        type={type}
                        className="add-data-form_form-form_container-item-field"
                        placeholder={ToNormalCase(field)}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="add-data-form_form-form-submit">
                <button
                  type="submit"
                  className="add-data-form_form-form-submit-button"
                >
                  Add Data
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default AddNewData;
